<template>
  <div>
    <title>SIM INVENTORY ~ ADD ORDER COMPONENT DETAILS LIST DATA</title>
    <section class="content-header">
      <h1>
        Add Order Component Detail List Data
        <br />
        <h4>Please enter the transaction data for the order component detail list</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction List Order Component Detail</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Data List Order Component Detail</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Part Code / Part Number </label>
                    <input
                      type="hidden"
                      v-model="isidata.iddaftarordd"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="text"
                      v-model="isidata.kodepartcomd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Please enter part code / part number"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getpart()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namapartcomd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="number"
                    v-model="isidata.qtyordercomd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit </label>
                  <input
                    type="text"
                    v-model="isidata.unitordercomd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/daftar-order">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        iddaftarordd: "",
        kodepartcomd: "",
        namapartcomd: "",
        qtyordercomd: "",
        unitordercomd: ""
      }
    };
  },
  created() {
    this.isidata.iddaftarordd = this.$route.params.id;
  },
  methods: {
    async getpart() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbypart_no?part_no=" + this.isidata.kodepartcomd;
      const urlAPIget =
        this.$apiurl +
        "part_component/getpart_componentbypart_no?part_no=" +
        this.isidata.kodepartcomd;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Part Code / Part Number not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodepartcomd = resp.data.data.part_no;
            this.isidata.namapartcomd = resp.data.data.name;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async saveData() {
      this.loading = true;
      var kodepartcomd = this.isidata.kodepartcomd;
      var qtyordercomd = this.isidata.qtyordercomd;
      var unitordercomd = this.isidata.unitordercomd;
      var iddaftarorderd = this.isidata.iddaftarordd;
      if (kodepartcomd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code / Part Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtyordercomd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unitordercomd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          id_daftar_order_d: iddaftarorderd,
          part_no: kodepartcomd,
          qty: qtyordercomd,
          unit: unitordercomd,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPISaveDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_component/savedaftar_order_d_component";
        const urlAPISaveDatadetail =
          this.$apiurl +
          "daftar_order_d_component/savedaftar_order_d_component";
        axios
          .post(urlAPISaveDatadetail, paramdatadetail, { headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
